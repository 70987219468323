import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SignupIdentityForm from "../components/formIdentitySignup"
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../styledComponents/section"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const SignUp = ({ data, location }) => {

  return (
    <>
     <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <Layout location={location} hideNewsletterSignUp>
      <Section>
          <Heading level={1} align="center">
            Sign Up
          </Heading>
          <SectionHeaderLedeDiv><p>Please sign up to access your meditations.</p></SectionHeaderLedeDiv>
          <SignupIdentityForm />
        </Section>
    </Layout>
    </>
  )
}

export default SignUp